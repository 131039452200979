import { useForm } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { IdsRadioButton, IdsIcon } from '@ids/react'
import { Navigation, If } from '@itau-loans-www/shopping/src/components'
import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'

import Shimmer from 'components/Shimmer'

import useMicroGoal from './hooks'
import './micro-goal.scss'

const MicroGoal = ({ pageContext }) => {
  const initialValues = { goal: '' }
  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { field } = useController({
    name: 'goal',
    control
  })

  const { setIsLoading, loading } = useProposal()
  const { changeLeadData } = useLead()

  const { onSubmit, submitLoading, radioOptions, subTitle } = useMicroGoal({
    setIsLoading,
    loading,
    changeLeadData
  })
  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={submitLoading}
        renderIf={
          <>
            <Shimmer
              margin="10px 0 40px 0"
              height="64px"
              heightDesktop="45px"
            />
            <Shimmer margin="0 0 10px 0" width="90px" height="30px" />
            <Shimmer margin="10px 0 20px 0" height="63px" />
            <Shimmer height="63px" />
            <Shimmer
              margin="20px 0 180px 0"
              marginDesktop="20px 0 120px 0"
              height="63px"
            />
            <Shimmer widthDesktop="50%" height="50px" />
          </>
        }
        renderElse={
          <>
            <h1 className="proposal-title">
              Vamos entender melhor: quais os objetivos com este pedido?
            </h1>
            <form className="form micro-goal" onSubmit={handleSubmit(onSubmit)}>
              <h2 className="micro-goal__subtitle">{subTitle}</h2>
              {radioOptions.map(({ micro_goal_id: id, icon, name }) => {
                return (
                  <IdsRadioButton
                    {...field}
                    key={id}
                    value={id}
                    name="noSelection"
                    onChange={(e) => field.onChange(e)}
                  >
                    <IdsIcon variant="neutral">{icon}</IdsIcon>
                    {name}
                  </IdsRadioButton>
                )
              })}
              <Navigation
                submit
                nextStep
                isNextButtonDisabled={!field.value}
                nextButtonId="micro-goal-next-btn"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default MicroGoal
